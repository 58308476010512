import {
  ASTHMA_REPORT_ACTION_SET_FROM_RESPONSE,
  ASTHMA_REPORT_SET_STATE,
} from "@/AsthmaReportConstants";
import {TIMELINE_SET_DATE_RANGE} from "@/components/timeline/TimelineConstants";
import cloneDeep from 'clone-deep';

export default {
  [ASTHMA_REPORT_ACTION_SET_FROM_RESPONSE]: function({ commit, state}, responseData) {
    commit(TIMELINE_SET_DATE_RANGE, {
      startDate: responseData.dateStart,
      endDate: responseData.dateEnd
    });

    commit(ASTHMA_REPORT_SET_STATE, {
      fetchedReport: responseData,
      report: {
        name: responseData.name,
        state: responseData.state,
        referringClinician: responseData.referringClinician,
        description: responseData.description,
        comments: responseData.comments,
        attachedReports: responseData.attachedReports,
        attachedReports2019: responseData.attachedReports2019,
        acq: cloneDeep(responseData.acq)
      }
    });
  }
};